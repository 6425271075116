import { IconProps } from '@clarke-energia/foton';
import ClarkeIcon from '@assets/images/Clarke Icon.svg';
import useWhiteLabel from '@hooks/use-white-label';
import useProductOne from '@hooks/use-product-one';
import useProductTwo from '@hooks/use-product-two';

export interface ICardContent {
  icon: IconProps['icon'];
  imageSvg: string;
  label: string;
  strong: string;
  label2: string;
}

export interface ICardItems {
  title: string;
  iconTitle: IconProps['icon'];
  imageSvg: string;
  cardContent: ICardContent[];
}

const validateChargeCoverage = (inputs, productType, risk) => {
  if (inputs?.aux__useManualAclPrice) {
    if (inputs?.manualAclPricesHasChargesCoverage) {
      return true;
    } else {
      return false;
    }
  } else if (
    (productType === 'ACL_WHOLESALER' && risk === 'BOLD') ||
    (productType === 'ACL_RETAILER' && risk === 'BOLD')
  ) {
    return false;
  } else {
    return true;
  }
};

const addEnergisaSuplleirBenefits = (commercialConditionsContent: ICardContent[]) => {
  commercialConditionsContent.unshift({
    icon: 'BuildingOfficeIcon',
    imageSvg: '',
    label: '',
    strong: 'Fornecedor de energia seguro',
    label2: ' com + R$ 100 milhões de patrimônio líquido',
  });
};

const addGuarantee = (commercialConditionsContent: ICardContent[]) => {
  commercialConditionsContent.push({
    icon: 'ShieldCheckIcon',
    imageSvg: '',
    label: '',
    strong: 'Sem garantia',
    label2: '',
  });
};

const getCommercialConditionsContent = () => {
  const { proposal: productOneProposal } = useProductOne();
  const { proposal: productTwoProposal } = useProductTwo();
  const isRetailPath = window.location.pathname.includes('clarke-varejo');
  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  const inputs = isManagementPath ? productOneProposal?.inputs : productTwoProposal?.inputs;
  const productType = inputs?.productType;
  const risk = inputs?.risk;

  const isExclusiveEnergisa = productType === 'ACL_WHOLESALER' ? false : true;
  const flexType = productType === 'ACL_WHOLESALER' && risk === 'BOLD' ? 30 : 100;
  const hasChargeCoverage = validateChargeCoverage(inputs, productType, risk);

  const commercialConditionsContent: ICardContent[] = [
    {
      icon: 'LightBulbIcon',
      imageSvg: '',
      label: 'Modalidade ',
      strong: productType === 'ACL_WHOLESALER' ? 'atacadista' : 'varejista',
      label2: '',
    },
    {
      icon: 'PresentationChartBarIcon',
      imageSvg: '',
      label: '',
      strong: `Flexibilidade +/- ${flexType}%`,
      label2: '',
    },
    {
      icon: 'DocumentIcon',
      imageSvg: '',
      label: '',
      strong: hasChargeCoverage ? 'Cobertura total de encargos' : 'Sem cobertura de encargos',
      label2: ' pelo contrato de energia',
    },
  ];

  isExclusiveEnergisa && addEnergisaSuplleirBenefits(commercialConditionsContent);
  isRetailPath && addGuarantee(commercialConditionsContent);
  return commercialConditionsContent;
};

export const ProposalCards = () => {
  const { isWhiteLabelProposal } = useWhiteLabel();
  const simplifiedProposalCardsData: ICardItems[] = [
    {
      title: 'Condições Comerciais',
      iconTitle: 'BriefcaseIcon',
      imageSvg: '',
      cardContent: getCommercialConditionsContent(),
    },
    {
      title: 'Conheça a Clarke',
      iconTitle: 'BuildingOfficeIcon',
      imageSvg: isWhiteLabelProposal ? '' : ClarkeIcon,
      cardContent: [
        {
          icon: 'BuildingOffice2Icon',
          imageSvg: '',
          label: '',
          strong: 'Maior empresa de tecnologia',
          label2: ' do mercado livre de energia',
        },
        {
          icon: 'BoltIcon',
          imageSvg: '',
          label: '',
          strong: 'Gestora Independente',
          label2: ' de Energia',
        },
        {
          icon: 'ComputerDesktopIcon',
          imageSvg: '',
          label: '',
          strong: 'Área do cliente',
          label2: ' simples e intuitiva',
        },
        {
          icon: 'CurrencyDollarIcon',
          imageSvg: '',
          label: '',
          strong: 'Menor custo',
          label2: ' de gestão do Brasil',
        },
        {
          icon: 'DocumentTextIcon',
          imageSvg: '',
          label: '',
          strong: 'Sem rescisão',
          label2: ' no contrato de gestão',
        },
      ],
    },
    {
      title: 'Mercado Livre de energia',
      iconTitle: 'BoltIcon',
      imageSvg: '',
      cardContent: [
        {
          icon: 'FlagIcon',
          imageSvg: '',
          label: '',
          strong: 'Proteção contra as bandeiras tarifárias',
          label2: '',
        },
        {
          icon: 'BanknotesIcon',
          imageSvg: '',
          label: '',
          strong: 'Previsibilidade orçamentária',
          label2: '',
        },
        {
          icon: 'ClockIcon',
          imageSvg: '',
          label: '',
          strong: 'Mesmo preço de energia',
          label2: ' em qualquer horário do dia',
        },
        {
          icon: 'GlobeAmericasIcon',
          imageSvg: '',
          label: ' Consumindo energia',
          strong: ' 100% renovável',
          label2: '',
        },
      ],
    },
  ];

  return simplifiedProposalCardsData;
};
