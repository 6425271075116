import React, { useState } from 'react';
import { createContext } from 'use-context-selector';
import { FeedbackNotificationProps } from '@clarke-energia/foton';

import { useAuth } from '@src/auth-wrapper';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { SimulatorsInputsTranslator } from '@contexts/calculator/simulator-inputs-translator';
import { SimulatorTypeEnum } from '@contexts/types';

import { getCalculatorName } from '@utils/common';

import { ICustomClipboardContext } from './types';
import {
  CustomClipboardFeedbackNotificationContents,
  readCustomClipboardValue,
  readUserClipboard,
  setCustomClipboardValue,
  writeUserClipboard,
} from './helper';

export const CustomClipboardContext = createContext({} as ICustomClipboardContext);

interface Provider {
  children: React.ReactNode;
}

export const CustomClipboardProvider: React.FC<Provider> = ({ children }: Provider) => {
  const { user } = useAuth();

  const [customClipboardFeedbackNotificationContent, setCustomClipboardFeedbackNotificationContent] =
    useState<FeedbackNotificationProps>();

  const feedbackNotificationsContents = CustomClipboardFeedbackNotificationContents(() =>
    setCustomClipboardFeedbackNotificationContent(undefined),
  );

  function CopyFormValues(formValues?: any, copyFromProposalPreview?: boolean, showFeedback = true) {
    if (formValues) {
      const stringifiedFormValues = JSON.stringify(formValues);

      setCustomClipboardValue(stringifiedFormValues);
      writeUserClipboard(stringifiedFormValues)
        .then(() => {
          showFeedback && setCustomClipboardFeedbackNotificationContent(feedbackNotificationsContents.COPY_SUCCESS);
        })
        .catch(() => {
          showFeedback && setCustomClipboardFeedbackNotificationContent(feedbackNotificationsContents.COPY_ERROR);
        });

      eventTracker.trackEvent(
        copyFromProposalPreview
          ? eventLabels.CLICK_COPY_INPUTS_IN_SIMULATION_DESCRIPTION
          : eventLabels.BUTTON_COPYDATA_SIMULATOR,
        {
          product: getCalculatorName(location.href),
          user_groups: user?.groups ? user?.groups.join(', ') : '',
          user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
        },
      );
    }
  }

  async function PasteFormValues(
    setInitialValues: React.Dispatch<React.SetStateAction<any>>,
    simulatorType: keyof typeof SimulatorTypeEnum,
    showFeedback = true,
    pasteFromUserClipboard = false,
  ) {
    try {
      const formValues = pasteFromUserClipboard ? await readUserClipboard() : readCustomClipboardValue();
      const parsedFormValues: Record<any, any> = formValues && JSON.parse(formValues);
      if (parsedFormValues) {
        const simulatorsInputsTranslator = new SimulatorsInputsTranslator(parsedFormValues);
        const translatedInputs = simulatorsInputsTranslator.translate(simulatorType);
        setInitialValues(translatedInputs);
        showFeedback && setCustomClipboardFeedbackNotificationContent(feedbackNotificationsContents.PASTE_SUCCESS);
      }
    } catch {
      showFeedback && setCustomClipboardFeedbackNotificationContent(feedbackNotificationsContents.PASTE_FAILURE);
    }

    await eventTracker.trackEvent(
      pasteFromUserClipboard ? eventLabels.BUTTON_RECOVERDATA_SIMULATOR : eventLabels.BUTTON_PASTEDATA_SIMULATOR,
      {
        product: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      },
    );
  }

  React.useEffect(() => {
    setCustomClipboardFeedbackNotificationContent(undefined);
  }, [location.href]);

  return (
    // @ts-expect-error -> generic error caused by external lib
    <CustomClipboardContext.Provider
      value={{ CopyFormValues, PasteFormValues, customClipboardFeedbackNotificationContent }}
    >
      {children}
    </CustomClipboardContext.Provider>
  );
};
