import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ButtonIcon, FeedbackNotification, FeedbackNotificationProps } from '@clarke-energia/foton';

import { useAuth } from '@src/auth-wrapper';

import { AclTypeEnum, IProposalLead, ISimulationPayloadBase, ProductTypeEnum } from '@contexts/types';
import useLeads from '@hooks/use-leads';

import { isStringEmpty, transformObjectKeysToCamelCase } from '@utils/common';
import { isValidDate } from '@utils/datetime';

import { GenericFormField } from '@components/atoms/form/fields/generic-form-field';
import { SelectFormField } from '@components/atoms/form/fields/select-form-field';
import ModalRegisterNewLead from '@components/molecules/simulations-list/modal-register-new-lead';
import { ComboBoxFormField } from '@components/atoms/form/fields/combobox-form-field';
import { RiskTypeEnum } from '@contexts/product-one/types';

import { fieldVerificator } from '../helper';
import { SimulatorFormTooltipsContentIndexer as tooltipsContent } from '../static-data';
import { compareAndGetProposalValidity } from '../default-values-getters';

interface ISimulatorFormGeneralSection<T> {
  fields?: Record<keyof T | string, boolean>;
}

const getCustomRegisterNewLeadFeedbackNotificationContents = (
  onCloseNotification: () => void,
): Record<string, FeedbackNotificationProps> => {
  return {
    REGISTER_LEAD_SUCCESS: {
      kind: 'primary',
      label: 'Sucesso',
      message: 'Grupo comercial registrado com sucesso.',
      onCloseNotification,
    },
    REGISTER_LEAD_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Não foi possível registrar o grupo comercial.',
      onCloseNotification,
    },
  };
};

function SimulatorFormGeneralSection<T>({ fields }: ISimulatorFormGeneralSection<T>) {
  const [isFetchingLead, setIsFetchingLead] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leads, setLeads] = useState<Array<IProposalLead>>([]);
  const [customRegisterNewLeadFeedbackNotificationContent, setCustomRegisterNewLeadFeedbackNotificationContent] =
    useState<FeedbackNotificationProps>();

  const {
    setValue,
    watch,
    formState: { defaultValues },
  } = useFormContext<ISimulationPayloadBase>();

  const { getLeads, createLead, getLeadById } = useLeads();
  const leadId = watch('lead');
  const aux__useManualAclPrice = watch('aux__useManualAclPrice');

  const handleCreateLead = async (name: string) => {
    const feedbackNotificationsContents = getCustomRegisterNewLeadFeedbackNotificationContents(() =>
      setCustomRegisterNewLeadFeedbackNotificationContent(undefined),
    );
    try {
      const response = await createLead({ name: name });
      if (response.data.success) {
        const createdLead = response.data.data;
        setIsModalOpen(false);
        fetchLeads();
        setValue('lead', createdLead.id);
        setCustomRegisterNewLeadFeedbackNotificationContent(feedbackNotificationsContents.REGISTER_LEAD_SUCCESS);
      } else throw Error;
    } catch {
      setIsModalOpen(false);
      setCustomRegisterNewLeadFeedbackNotificationContent(feedbackNotificationsContents.REGISTER_LEAD_FAILURE);
    }
  };

  const parseLeads = (leads?: IProposalLead[]): Array<IProposalLead> | undefined => {
    if (leads && leads.length > 0) {
      return transformObjectKeysToCamelCase(leads) as Array<IProposalLead>;
    }
  };

  const transformLeadsToInputOptions = (leads: IProposalLead[]) => {
    const transformedLeadListIntoInputOptions = leads.map((lead: IProposalLead) => ({
      value: lead.id,
      label: lead.name,
    }));

    return transformedLeadListIntoInputOptions;
  };

  const transformedLeadListIntoInputOptions = leads && transformLeadsToInputOptions(leads);

  const aglutinateLeads = (currentParsedLeads: Array<IProposalLead>, parsedLeads: Array<IProposalLead>) => {
    return [...new Set([...currentParsedLeads, ...parsedLeads])];
  };

  const fetchLeads = (page?: number, name?: string) => {
    setIsFetchingLead(true);
    getLeads(page, name, undefined, undefined, undefined)
      .then((response) => {
        if (response.data.success) {
          const parsedLeads = parseLeads(response.data.data.data);
          parsedLeads && setLeads((currentParsedLeads) => aglutinateLeads(currentParsedLeads, parsedLeads));
        }
      })
      .finally(() => setIsFetchingLead(false));
  };

  const fetchLeadById = (id?: string) => {
    setIsFetchingLead(true);
    getLeadById(id)
      .then((response) => {
        if (response.data.success) {
          const parsedLeads = parseLeads([response.data.data]);
          parsedLeads && setLeads((currentParsedLeads) => aglutinateLeads(currentParsedLeads, parsedLeads));
        }
      })
      .finally(() => setIsFetchingLead(false));
  };
  const isManagementPath = window.location.pathname.includes('clarke-gestao/calculadora');

  const currentProposalValidity = watch('proposalValidity');
  const reviewProposalValidityFromDefaultValues = () => {
    const safeProposalValidity = isManagementPath
      ? compareAndGetProposalValidity(currentProposalValidity, 'CLARKE_MANAGEMENT')
      : compareAndGetProposalValidity(currentProposalValidity);
    setValue('proposalValidity', safeProposalValidity);
  };

  useEffect(() => {
    if (leadId) {
      fetchLeadById(leadId);
    }

    reviewProposalValidityFromDefaultValues();
  }, [leadId, defaultValues]);

  useEffect(() => {
    if (!leadId) {
      fetchLeads();
    }
  }, []);

  const { user } = useAuth();
  const userIsCommercialDealer = user?.isCommercialDealer == true;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4 mb-6">
      {customRegisterNewLeadFeedbackNotificationContent && (
        <div className="fixed right-0 bottom-0 my-6 mx-6 max-w-full lg:w-fit">
          <FeedbackNotification {...customRegisterNewLeadFeedbackNotificationContent} />
        </div>
      )}
      <div className="grid grid-cols-2 col-span-full gap-y-6 gap-x-7">
        <div className="flex items-end col-span-1 md:col-span-2">
          <div className="w-full">
            <ComboBoxFormField<ISimulationPayloadBase>
              id="lead"
              field="lead"
              label="Grupo Comercial"
              inputOptions={transformedLeadListIntoInputOptions}
              options={{ required: { value: true, message: 'O Grupo Comercial é obrigatório' } }}
              onInputChange={(value) => {
                value && fetchLeads(undefined, value);
              }}
              isLoading={isFetchingLead}
            />
          </div>
          <ButtonIcon
            className="h-7 w-7 ml-4 "
            icon="PlusSmallIcon"
            kind="secondary"
            onClick={() => setIsModalOpen(true)}
            type="button"
          />
        </div>
        <div className="col-span-1 md:col-span-2">
          <GenericFormField id="description" field="description" label="Descrição" placeholder="" />
        </div>
        {(isManagementPath || aux__useManualAclPrice) &&
          fieldVerificator<T>({ fieldName: 'proposalValidity', fieldPriority: 'PRIMARY', fields: fields }) && (
            <GenericFormField
              id="proposalValidity"
              field="proposalValidity"
              label="Validade da Proposta"
              tooltipContent={tooltipsContent.proposalValidity}
              placeholder="dd/mm/aaaa"
              disabled={userIsCommercialDealer}
              options={{
                validate: (value) =>
                  isStringEmpty(value) ? undefined : isValidDate(value as string) || 'Insira uma data válida',
              }}
            />
          )}
        {!user?.isCommercialDealer &&
          fieldVerificator<T>({ fieldName: 'aclType', fieldPriority: 'PRIMARY', fields: fields }) && (
            <SelectFormField
              id="aclType"
              field="aclType"
              label="Tipo de Cálculo"
              inputOptions={Object.entries(AclTypeEnum).map((aclTypeEntry) => ({
                value: aclTypeEntry[0],
                optionLabel: aclTypeEntry[1],
              }))}
              options={{ required: { value: true, message: 'O tipo de cálculo é obrigatório' } }}
              tooltipContent={tooltipsContent.aclType}
            />
          )}
        {fieldVerificator<T>({ fieldName: 'productType', fieldPriority: 'SECONDARY', fields: fields }) && (
          <SelectFormField
            id="productType"
            field="productType"
            label="Modalidade de Contratação"
            inputOptions={Object.entries(ProductTypeEnum).map((productTypeEntry) => ({
              value: productTypeEntry[0],
              optionLabel: productTypeEntry[1],
            }))}
            options={{ required: { value: true, message: 'Modalidade de contratação é obrigatória' } }}
          />
        )}
        {fieldVerificator<T>({ fieldName: 'productType', fieldPriority: 'SECONDARY', fields: fields }) && (
          <SelectFormField
            id="risk"
            field="risk"
            label="Cenário de risco"
            inputOptions={Object.entries(RiskTypeEnum).map((riskTypeEntry) => ({
              value: riskTypeEntry[0],
              optionLabel: riskTypeEntry[1],
            }))}
            options={{ required: { value: true, message: 'Cenário de risco é obrigatório' } }}
          />
        )}
        {isModalOpen && (
          <ModalRegisterNewLead
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleCreateLead={handleCreateLead}
          />
        )}
      </div>
    </div>
  );
}
export default SimulatorFormGeneralSection;
