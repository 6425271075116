import { getGroupInfoTableData } from '@pages/detailed-analysis/helper';

import { DataTable } from '@components/atoms/detailedAnalysisGC/dataTable';

import { DropdownSection } from './dropdonw';

import { ISimulationResultBase } from '@contexts/types';
import { Paper } from '@mui/material';

export function GenerateTableGroupInfo({ proposalData }: { proposalData: ISimulationResultBase }) {
  const groupInfoTableData = getGroupInfoTableData(proposalData);
  return (
    <>
      <h3 className="text-lg font-semibold mb-4 flex items-center">Dados do Grupo</h3>
      <DataTable data={groupInfoTableData} />
    </>
  );
}

export default GenerateTableGroupInfo;
