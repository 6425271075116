import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useAuth } from '@src/auth-wrapper';

import { defaultNumericFormFieldOptionsAsRequired } from '@utils/form';

import { ISimulationPayloadBase, ISimulationUnitPayloadBase } from '@contexts/types';
import { parseUnitConsumptionHistory } from '@contexts/calculator/units-payload-handlers';
import useWhiteLabel from '@hooks/use-white-label';

import { NumericFormField } from '@components/atoms/form/fields/numeric-form-field';
import { CheckboxFormField } from '@components/atoms/form/fields/checkbox-form-field';
import { fieldVerificator } from '@components/molecules/form/simulator/helper';
import { SimulatorFormTooltipsContentIndexer as tooltipsContent } from '@components/molecules/form/simulator/static-data';
import { calculateManagementPriceSuggestion } from '@components/atoms/form/simulation-units-section/management-price-suggestion';

interface ISimulatorUnitBudgetSubsection {
  fieldsController?: Record<string, boolean>;
  unitNum: number;
}
export function SimulatorUnitBudgetSubsection({ unitNum, fieldsController }: ISimulatorUnitBudgetSubsection) {
  const { setValue } = useFormContext<ISimulationPayloadBase>();
  const [unitValues, aux__useManualManagementPrice] = useWatch<ISimulationPayloadBase>({
    name: [`units.${unitNum}`, 'aux__useManualManagementPrice'],
  });
  const aux__useManualManagementPriceValue = aux__useManualManagementPrice as boolean;

  const { user } = useAuth();
  const { whiteLabelSupplier } = useWhiteLabel();
  const handleManagementValueButton = () => {
    const { consumptionOffPeak, consumptionPeak } = parseUnitConsumptionHistory({
      ...(unitValues as ISimulationUnitPayloadBase),
    }) as ISimulationUnitPayloadBase;
    const suggestedManagementPrice =
      user?.isWhiteLabel && whiteLabelSupplier?.prices?.management
        ? whiteLabelSupplier.prices?.management
        : calculateManagementPriceSuggestion(consumptionOffPeak, consumptionPeak);

    setValue(`units.${unitNum}.managementPrice`, suggestedManagementPrice as number);
  };

  React.useEffect(() => {
    if (whiteLabelSupplier?.prices?.management) {
      handleManagementValueButton();
    }
  }, [whiteLabelSupplier]);

  const unitConsumptionsWatch = useWatch({
    name: [`units.${unitNum}.consumptionOffPeak`, `units.${unitNum}.consumptionPeak`],
  });

  React.useEffect(() => {
    if (aux__useManualManagementPriceValue) handleManagementValueButton();
  }, [unitConsumptionsWatch, whiteLabelSupplier, aux__useManualManagementPriceValue]);

  if (!user?.isCommercialDealer) {
    return (
      <div>
        <div className="flex flex-col mb-5">
          <h2 className="text-paragraph-large font-bold">Orçamento</h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-4">
          <div className="grid grid-cols-1">
            {fieldVerificator({
              fieldName: 'managementPrice',
              fieldPriority: 'PRIMARY',
              fields: fieldsController,
            }) && (
              <div className="flex flex-col gap-3">
                <NumericFormField<ISimulationPayloadBase>
                  id={`units.${unitNum}.managementPrice`}
                  field={`units.${unitNum}.managementPrice`}
                  label="Custo de Gestão / mês"
                  formatProps={{
                    mask: '_',
                    allowNegative: false,
                    fixedDecimalScale: false,
                    decimalSeparator: ',',
                    thousandSeparator: '.',
                    decimalScale: 10,
                    prefix: 'R$ ',
                  }}
                  options={defaultNumericFormFieldOptionsAsRequired}
                  disabled={aux__useManualManagementPriceValue}
                  tooltipContent={tooltipsContent.unit.managementPrice}
                />
                <CheckboxFormField<ISimulationPayloadBase>
                  field="aux__useManualManagementPrice"
                  data-cy="enableAutomaticManagementPrice"
                  label=""
                  inputOptions={[
                    {
                      id: 'enableAutomaticManagementPrice',
                      value: 'enableAutomaticManagementPrice',
                      optionLabel: 'Calcular automaticamente',
                      defaultChecked: aux__useManualManagementPriceValue as boolean,
                    },
                  ]}
                />
              </div>
            )}
          </div>
          {fieldVerificator({
            fieldName: 'initialExpenses',
            fieldPriority: 'PRIMARY',
            fields: fieldsController,
          }) && (
            <NumericFormField<ISimulationPayloadBase>
              id={`units.${unitNum}.initialExpenses`}
              field={`units.${unitNum}.initialExpenses`}
              label="Investimento Inicial"
              formatProps={{
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: ',',
                thousandSeparator: '.',
                decimalScale: 10,
                prefix: 'R$ ',
              }}
              tooltipContent={tooltipsContent.unit.initialExpenses}
            />
          )}
        </div>
      </div>
    );
  } else return <></>;
}
